import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => 
            localStorage.getItem("token") ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login"}} />
            )            
        }
        />
)

export default ProtectedRoute